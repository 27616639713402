<template>
    <div class="accordion">
        <ul class="advantage-box">
            <li
                v-for="(item, i) in advantageList"
                :key="i"
                :class="i == adActive ? 'isActive' : ''"
                @mouseenter="adEnter(i)"
                :style="{backgroundImage: 'url(' + item.bgSrc + ')'}"
            >
                <p v-show="i != adActive" class="isfold">
                    {{ item.title.substr(0, 3) }}<br />{{
                        item.title.substr(3)
                    }}
                    <!-- 专业的<br/>品牌团队 -->
                </p>
                <div v-show="i == adActive" class="notfold">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.contentA }}</p>
                    <p v-show="item.contentB != ''">{{ item.contentB }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'accordion',
    props: {
        advantageList: {
            type: Array
        }
    },
    data() {
        return {
            adActive: 0
        }
    },
    watch: {
    },
    methods: {
        adEnter(i) {
            this.adActive = i
        }
    }
}
</script>
<style lang="scss" scoped>
.accordion{
    width: 100%; 
    .advantage-box{
        width: 100%;
        height: 480px;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        li{
            list-style: none;
            overflow: hidden;
            border-radius: 4px;
            cursor: pointer;
            transition: width 1s ease-in-out, opacity 1s ease-in-out;
            &:nth-child(1){
                // background: url('../../assets/images/logo/蒙版组31@2x.png');
                width: 197px; //429px
                background-position: 45% 0;
                background-size: 429px 480px;
            }
            &:nth-child(2){
                // background: url('../../assets/images/logo/蒙版组35@2x.png');
                width: 197px;
                background-position: 45% 0;
                background-size: 429px 480px;
            }
            &:nth-child(3){
                // background: url('../../assets/images/logo/蒙版组34@2x.png');
                width: 197px;
                background-position: 45% 0;
                background-size: 429px 480px;
            }
            &:nth-child(4){
                // background: url('../../assets/images/logo/蒙版组33@2x.png');
                width: 197px;
                background-position: 45% 0;
                background-size: 429px 480px;
            }
            &:nth-child(5){
                // background: url('../../assets/images/logo/蒙版组32@2x.png');
                width: 197px;
                background-size: 429px 480px;
                background-position: 45% 0;
            }
            &.isActive{
                width: 429px;
            }
        }
        .isfold{
            // background: chartreuse;
            // width: 130px;
            height: 80px;
            font-size: 20px;
            font-family: HarmonyOS Sans SC;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            letter-spacing: 2px;
            margin-left: 28px;
            margin-top:calc(480px - 80px - 40px) ;
            position: relative;
            &::after{
                content: '';
                display: inline-block;
                position: absolute;
                top: 78px;
                left: 0;
                height: 0;
                width: 28px;
                border: 1px solid #fff;
            }
        }
        .notfold{
            width: 100%;
            height: 100%;
            background: #00227D;
            opacity: 0.8;
            border-radius: 4px;
            padding: 166px 41px 0 28px;
            h3{
                width: 280px;
                font-size: 24px;
                font-family: HarmonyOS Sans SC;
                font-weight: bold;
                line-height: 59px;
                color: #FFFFFF;
                letter-spacing: 3px;
                position: relative;
                &::after{
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 62px;
                    left: 0;
                    height: 0;
                    width: 28px;
                    border: 1px solid #fff;
                }
            }
            p{
                width: 359px;
                font-size: 14px;font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
                font-weight: 400;
                line-height: 26px;
                color: #FFFFFF;
                letter-spacing: 2px;
            }
        }
    }
}
</style>