<template>
    <div v-show="navShow" class="nav" :class="[nowNav, navMax]" :style="{top:navTop+'px'}">
        <div class="nav-main">
            <div class="nav-left">
                <img v-if="nowNav == 'homeNav' && navMax!='elseNav'" src="../assets/images/logo/LOGOA.png" alt="">
                <img v-else src="../assets/images/logo/LOGOB.png" alt="">
            </div>
            <ul class="nav-center">
                <li v-for="(route, i) in routes" :key="i">
                    <router-link :to="route.path" :class="nowRoute == 'detail' && route.name == 'mediaCenter'?'router-link-exact-active':''">
                        {{route.meta.name}}
                    </router-link>
                </li>
            </ul>
            <div class="nav-right" @mouseenter="enter" @mouseleave="leave">
                <div v-if="codeHover == false" class="nav-code">
                    <img src="../assets/images/logo/二维码动效.gif" alt="" />
                    <img src="../assets/images/logo/好酒@2x.png" alt="" />
                </div>
                <div v-else class="nav-hover">
                    <img src="../assets/images/logo/二维码蓝色@2x.png" alt="" />
                    <img src="../assets/images/logo/字蓝色@2x.png" alt="" />
                </div>
                 <!-- v-if="codeHover" -->
                <transition name="draw">
                    <div v-show="codeHover" class="nav-hover-box">
                        <img src="../assets/images/logo/b2Code.png" alt="">
                        <h3>下载好酒集商户端app</h3>
                        <img src="../assets/images/logo/b3Code.png" alt="">
                        <h3>下载好酒集采购端app</h3>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Nav',
    computed: {
        nowRoute() {
            return this.$route.name
        },
        nowNav() {
            if (this.$route.name == 'Home') {
                return 'homeNav'
            } else {
                return 'elseNav'
            }
        },
        routes() {
            return this.$router.options.routes
        }
    },
    data() {
        return {
            codeHover: false,
            windowHeight: document.documentElement.clientHeight,
            navMax: '',
            oldScrollTop: 0,
            navShow: true,
            navTop: 0
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollToTop)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollToTop)
    },
    methods:{
        enter() {
            this.codeHover = true
        },
        leave() {
            this.codeHover = false
        },
        scrollToTop() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if(this.$route.name == 'Home'){
                this.navTop = '0'
                // scrollTop + 100 >= document.documentElement.clientHeight
                if(scrollTop + 100 >= document.documentElement.clientHeight / 2 - 50){ // 100为nav自身高度
                    this.navMax = 'elseNav'
                } else {
                    this.navMax = 'homeNav'
                }
            } else if(this.$route.name == 'serviceProducts' || this.$route.name == 'solution' || this.$route.name == 'mediaCenter'){
                let scroll = scrollTop - this.oldScrollTop
                this.oldScrollTop = scrollTop
                if(scroll<=0){
                    this.navTop = '0'
                    // this.navShow = true
                    // console.log('up')
                }else{
                    this.navTop = '-100'
                    // this.navShow = false
                    // console.log('down')
                }
            } else {
                this.navTop = '0'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.nav {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    transition: all 0.5s linear;
    .nav-main{
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
    }
    .nav-left {
        width: 20%;
        font-size: 26px;
        text-align: left;
        img{
            width: 170px;
            height: 32px;
        }
    }
    .nav-right {
        text-align: right;
        width: 80px;
        height: 38px;
        border-radius: 10px;
        cursor: pointer;
    }
    .nav-center {
        width:70%;
        padding: 0;
        margin: 0 20px 0 0 ;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        // li{
        //     box-sizing: border-box;
        //     list-style: none;
        //     cursor: pointer;
        //     font-size: 15px;
        //     padding: 13px 30px;
        //     &.active{
        //         background: #0037cc;
        //         border-radius: 24px;
        //     }
        // }
        li {
            box-sizing: border-box;
            .router-link-exact-active {
                background: #0037cc !important;
                transition: background 2s ease;
            }
            list-style: none;
            box-sizing: border-box;
            a:-webkit-any-link {
                display: inline-block;
                text-align: center;
                margin: 0 0px;
                padding: 13px 30px;
                border-radius: 24px;
                font-size: 15px;
                text-decoration: none;
                box-sizing: border-box;
                // background: chartreuse;
            }
        }
    }
    .nav-hover, .nav-code{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 16px;
            &:first-child{
                margin-right: 6px;
            }
        }
    }
    .nav-hover-box{
        position: relative;
        z-index: 999;
        margin-top: 10px;
        width: 178px;
        height: 392px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 34, 128, 0.12);
        opacity: 1;
        border-radius: 4px;
        text-align: center;
        overflow: hidden;
        h3{
            margin: 0;
            font-size: 12px;
            color: #111A37;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        img{
            margin-top: 18px;
            width: 140px;
            height: 140px;
        }
    }
}
.homeNav {
    background-color: rgba(43, 42, 42, 0);
    .nav-left {
        color: #fff;
    }
    .nav-right{
        background: #fff;
    }
    li {
        color: #fff;
        a:-webkit-any-link {
            color: #fff;
        }
    }
}
.elseNav {
    background: #fff;
    .nav-right{
        background: #F7F9FC;
    }
    li {
        color: #111a37;
        // &.active{
        //     color: #fff;
        // }
        .router-link-exact-active {
            color: #fff !important;
        }
        a:-webkit-any-link {
            color: #111a37;
        }
    }
}
</style>
