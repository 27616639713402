<template>
    <div class="home">
        <!-- 轮播 -->
        <div class="view" :style="{'height':windowHeight+'px'}">
            <div
                :class="{ banner: true, trans: flag }"
                @mouseenter="enter"
                @mouseleave="leave"
            >
                <img
                    :src="
                        require('../assets/images/banner/' +
                            imagesUrl[imgindex] +
                            '.jpg')
                    "
                    alt=""
                    class="img"
                    :style="{'height':windowHeight+'px'}"
                />
            </div>
            <!-- <div class="casoul-content">
                <h3>白酒产业链综合服务商</h3>
                <h4>Liquor industry  chain comprehensive service</h4>
                <h5>酒厂 | 酒商 | 经销商 | 零售终端</h5>
                <div class="casoul-btn">MORE</div>
            </div> -->
            <ul>
                <li
                    v-for="(item, index) in imagesUrl.length"
                    style="cursor: pointer;"
                    @click="select(index)"
                    :class="{ con: index === imgindex }"
                    :key="index"
                ></li>
                <!-- 点击小圆点切换对应图片展示 -->
            </ul>
            <img
                src="../assets/images/logo/left.png"
                alt=""
                @click="prev"
                class="prev"
            />
            <img
                src="../assets/images/logo/right.png"
                alt=""
                @click="next"
                class="next"
            />
            <img src="../assets/images/banner/shubiao.png" alt="" class="shubiao">
        </div>
        <!-- 酒星简介 -->
        <div class="home-main">
            <div class="main-left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="">
            </div>
            <div class="main-center">
                <div class="center-left">
                    <h3>酒星科技</h3>
                    <img src="../assets/images/logo/组110@2x.png" alt="" class="img-circle">
                    <h4>Introduction</h4>
                    <img src="../assets/images/logo/蒙版组3010@2x.png" alt="" class="center-img">
                </div>
                <div class="center-right">
                    <img src="../assets/images/logo/圆组装饰@2x.png" alt="" class="right-bg">
                    <div class="right-content">
                        <p>
                            四川酒星科技有限公司（简称酒星科技）是一家国内领先的白酒产业链综合服务商，隶属于四川美赢集团，扎根酒饮行业近10年。
                        </p>
                        <p>
                            公司以2B、2C相结合的业务模式，深度融合线上线下交易、消费场景，利用白酒生产技术、品牌运作、市场营销、产业金融等手段，创新性的为酒厂、酒商、经销商、零售终端提供白酒产业链上下游一站式综合服务。
                        </p>
                        <div class="center-btn">
                            MORE
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-right">
                <img src="../assets/images/logo/科技线@2x.png" alt="">
            </div>
        </div>
        <!-- 主营业务 -->
        <div class="home-mainb">
            <div class="main-leftb">
                <img src="../assets/images/logo/标题装饰-暗色背景.png" alt="">
            </div>
            <div class="main-centerb">
                <div class="centerb-top">
                    <div class="centerb-left">
                        <h3>主营业务</h3>
                        <img src="../assets/images/logo/标题白8@2x.png" alt="" class="img-circle">
                        <h4>business</h4>
                    </div>
                    <div class="centerb-right">
                        <div>
                            <span v-for="(item,i) in spanList" :key="i" :class="spanActive==i?'span-active':''" @click="spanChange(i)">
                                {{ item.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="centerb-bottom">
                    <div class="cb-left">
                        <img :src="require('../assets/images/logo/' + nowSpan.srcA+'.png')" alt="">
                        <img :src="require('../assets/images/logo/' + nowSpan.srcB+'.png')" alt="">
                    </div>
                    <div class="cb-center">
                        <img :src="require('../assets/images/logo/' + nowSpan.srcC+'.png')" alt="">
                    </div>
                    <div class="cb-right">
                        <h3>{{ nowSpan.index }}</h3>
                        <h4>{{ nowSpan.name }}</h4>
                        <p>{{ nowSpan.content }}</p>
                        <div class="center-btn">
                            <button>MORE</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-rightb">
                <img src="../assets/images/logo/科技线暗色背景.png" alt="">
            </div>
        </div>
        <!-- 解决方案 -->
        <div class="home-mainc">
            <div class="main-leftb">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="">
            </div>
            <div class="main-centerb">
                <div class="centerb-top">
                    <div class="centerb-left">
                        <h3>解决方案</h3>
                        <img src="../assets/images/logo/组110@2x.png" alt="" class="img-circle">
                        <h4>solution</h4>
                    </div>
                    <div class="centerb-right">
                        <p>
                            酒星科技赋能生态链贯穿
                            了白酒产业上中下游全链条，
                            针对酒厂（Factory）、酒商
                            （Business）、<br/>零售商户（Sale）
                            、消费者（customer），围绕销售、
                            品牌、产品、金融、技术五大版块，构建FB<br/>SC全生命周期赋能解决方案。
                        </p>
                    </div>
                </div>
                <!-- 解决方案 -->
                <div class="centerb-bottom" style="margin-top:48px">
                    <ul class="center-ula">
                        <li><div class="img-box"><img src="../assets/images/logo/xiaoshou@2x.png" alt=""></div>
                            <span>销售赋能</span></li>
                        <li>
                            <div class="img-box"><img src="../assets/images/logo/chanpin@2x.png" alt=""></div>
                            <span>产品赋能</span>
                        </li>
                        <li><div class="img-box"><img src="../assets/images/logo/蒙版组3002@2x.png" alt=""></div>
                            <span>品牌赋能</span></li>
                        <li><div class="img-box"><img src="../assets/images/logo/蒙版组3003@2x.png" alt=""></div>
                            <span>金融赋能</span></li>
                        <li><div class="img-box"><img src="../assets/images/logo/蒙版组3004@2x.png" alt=""></div>
                            <span>技术赋能</span></li>
                    </ul>
                    <img src="../assets/images/logo/圆组装饰@2x.png" alt="" class="cirle-img">
                </div>
            </div>
            <div class="main-rightb">
                <img src="../assets/images/logo/科技线@2x.png" alt="">
            </div>
        </div>
        <!-- 我们的优势 -->
        <div class="home-maind">
            <div class="main-leftb">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="">
            </div>
            <div class="main-centerb">
                <div class="centerb-top">
                    <div class="centerb-left">
                        <h3>我们的优势</h3>
                        <img src="../assets/images/logo/组110@2x.png" alt="" class="img-circle">
                        <h4>advantage</h4>
                    </div>
                </div>
                <!-- 我们的优势 -->
                <div class="centerb-bottom">
                    <Accordion :advantageList="advantageList"></Accordion>
                    <!-- <img src="../assets/images/logo/圆9排10列.png" alt="" class="img-circleb"> -->
                </div>
            </div>
            <div class="main-rightb">
                <img src="../assets/images/logo/科技线@2x.png" alt="">
            </div>
        </div>
        <!-- 招商产品 -->
        <div class="home-mainc home-maine">
            <div class="main-leftb">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="">
            </div>
            <div class="main-centerb">
                <div class="centerb-top">
                    <div class="centerb-left">
                        <h3>招商产品</h3>
                        <img src="../assets/images/logo/组110@2x.png" alt="" class="img-circle">
                        <h4>partners</h4>
                    </div>
                </div>
                <!-- 招商产品 -->
                <div class="centerb-bottom">
                    <div class="imgs-box">
                        <img src="../assets/images/logo/pic_zs_1@2x.png" alt="">
                        <img src="../assets/images/logo/pic_zs_2@2x.png" alt="">
                        <img src="../assets/images/logo/pic_zs_3@2x.png" alt="">
                        <img src="../assets/images/logo/pic_zs_4@2x.png" alt="">
                    </div>
                    <img src="../assets/images/logo/圆组装饰@2x.png" alt="" class="cirle-img-e">
                </div>
            </div>
            <div class="main-rightb">
                <img src="../assets/images/logo/科技线@2x.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from '../components/accordion/accordion.vue'
export default {
    name: 'Home',
    components:{
        Accordion
    },
    data() {
        return {
            imgindex: 0, //控制变量，默认显示第一张
            imagesUrl: ['banner', 'banner', 'banner'],
            flag: true,
            timer: null, //定时器
            windowHeight: document.documentElement.clientHeight,
            nowSpan:{
                index:'',
                name:'',
                srcA:'',
                srcB:'',
                srcC:'',
                content:''
            },
            spanList:[
                {index:'01',name:'次新酒运营',srcA:'jx-b',srcB:'jx-a',srcC:'jx-c',content:'酒星科技是西南片区最大的次新酒交易商，郎酒次新酒运营领域交易规模位列全国第一。'},
                {index:'02',name:'自营产品运营',srcA:'pic_zs_1@2x',srcB:'蒙版组3022@2x',srcC:'pic_zs_2@2x',content:'酒星科技专注自运营产品开发，为酒商和消费者带来更多更优质的白酒品牌及产品。'},
                {index:'03',name:'好酒集平台',srcA:'j3',srcB:'j2',srcC:'j1',content:'酒星科技旗下一款自主研发的白酒供应链流通交易综合服务平台。'}
            ],
            spanActive: 0,
            advantageList:[
                {title:'线上运营+线下销售',
                contentA:'线上运营网络：已布局全网销售渠道，通过自媒体矩阵运营、媒介资源整合、联合推广、数据分析模型等传递品牌及产品价值，已实现全网媒介平台覆盖，自运营自媒体账号全网粉丝达20W+。',
                contentB:'线下销售网络：覆盖9万+行业客户，深度合作渠道5千+，拥有10万+忠实消费者，业务覆盖28个省份，374个市级城市。',
                bgSrc: require('@/assets/images/logo/蒙版组31@2x.png')
                },
                {title:'顶尖的酒星专家团队',
                contentA:'行业顶级酒体专家顾问团队，从酒体品鉴、酒体勾调、生产技术升级，深度赋能产品，已链接30+家酒厂。',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组35@2x.png')
                },
                {title:'专业的酒星品牌团队',
                contentA:'20+位行业经验丰富的品牌策划及设计人员，从品牌定位、产品策略、包装设计、运营销售，全生命周期赋能品牌及产品',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组34@2x.png')
                },
                {title:'领先的酒星开发团队',
                contentA:'30+国内领先的互联网技术开发团队；开发系统涵盖交易系统、业务管理系统、支付系统、智慧金融系统、智能决策系统等；运用涵盖互联网技术、区块链技术、AI技术、大数据、物联网等。',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组33@2x.png')
                },
                {title:'雄厚的酒星金融实力',
                contentA:'目前累计放款额达1.5亿+，服务客户300+，日均放款额达100万+。',
                contentB:'',
                bgSrc: require('@/assets/images/logo/蒙版组32@2x.png')
                },
            ]
        }
    },
    created() {
        //还可以使用箭头函数就不用bind
        this.timer = setInterval(() => {
            this.next()
        }, 5000)
        this.nowSpan = this.spanList[0]
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight
                this.windowHeight = window.fullHeight // 高
                // console.log('实时屏幕高度：', this.windowHeight)
            })()
        }
    },
    beforeDestroy() {
        window.onresize = null
    },
    methods: {
        //下一张
        next: function() {
            if (this.imgindex == this.imagesUrl.length - 1) {
                this.imgindex = 0
            } else {
                this.imgindex++
            }
        },
        //上一张
        prev: function() {
            if (this.imgindex == 0) {
                this.imgindex = this.imagesUrl.length - 1
            } else {
                this.imgindex--
            }
        },
        select(index) {
            this.imgindex = index
            //通过点击小圆点获取对应的索引，进行赋值；
        },
        // 鼠标移入view
        enter: function() {
            clearInterval(this.timer)
        },
        leave: function() {
            this.timer = setInterval(this.next.bind(this), 5000)
        },
        spanChange(i) {
            this.spanActive = i
            this.nowSpan = this.spanList[i]
        }
    }
}
</script>
<style lang="scss" scoped>
%h3-common{
    font-size: 46px;
    color: #00227D;
    letter-spacing: 3px;
    margin: 0;
    font-weight: bold;
}
%h4-common{
    margin: 0;
    text-transform: uppercase;
    color: #2B4079;
    opacity: 0.2;
    font-size: 26px;
    letter-spacing: 3px;
    font-family: HarmonyOS Sans SC;
    font-weight: 900;
}
%img-circle-common{
    width: 28px;
    height: 13px;
    margin: 20px 0;
}
%box-common{
    box-sizing: border-box;
    width: 1280px;
    display: flex;
    justify-content: space-between;
}
.home {
    width: 100%;
    height: 100%;
    .casoul-content{
        position: absolute;
        top: 38%;
        left: 250px;
        color: #fff;
        h3{
            margin: 0;
            font-size: 80px;
            letter-spacing: 5px;
            color: #fff;
            font-weight: bold;
        }
        h4{
            font-size: 14px;
            letter-spacing: 3px;
            font-family: HarmonyOS Sans SC;
            text-transform: uppercase;
            margin: 20px 0 0 0;
            color: #fff;
        }
        h5{
            margin: 10px 0 30px 0;
            font-size: 20px;
            letter-spacing: 6px;
            color: #fff;
        }
        .casoul-btn{
            cursor: pointer;
            box-sizing: border-box;
            width: 170px;
            background: #0037CC;
            height: 60px;
            text-align: center;
            line-height: 60px;
            border-radius: 44px;
            font-size: 20px;
            font-family: HarmonyOS Sans;
            font-weight: 400;
            color: #FFFFFF;
            letter-spacing: 2px;
        }
    }
    .img {
        width: 100%;
    }
    .banner {
        overflow: hidden;
    }
    .trans {
        transition: all 0.5s;
    }
    .view {
        width: 100%;
        overflow: hidden;
        position: relative;
    ul {
        width: 300px;
        height: 13px;
        position: absolute;
        bottom: 53px;
        left: 90px;
        display: flex;
    }
    ul li {
        width: 13px;
        height: 13px;
        margin: 0 10px;
        list-style: none;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2);
        border: 2px solid rgba(255, 255, 255, 0.8);
        // transition: width 2s;
    }
    .con {
        transition: width 2s ease;
        width: 50px;
        border-radius: 10px;
        background: #fff;
    }
    }
    .prev {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 56px;
        width: 46px;
    }
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 56px;
        width: 46px;
    }
    .shubiao{
        position:absolute;
        bottom: 60px;
        right: 70px;
        height: 149.5px;
    }
    .home-main{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 140px 0;
        .main-left{
            width: calc((100% - 1280px) / 2 );
            img{
                margin-top: 25px;
                width: 100%;
            }
        }
        .main-center{
            height: 600px;
            @extend %box-common;
            .center-left{
                width: 56%;
                height: 100%;
                // background: rgb(255, 51, 0);
                h3{
                    @extend %h3-common;
                }
                h4{
                    @extend %h4-common;
                }
                .img-circle{
                    @extend %img-circle-common;
                }
                .center-img{
                    margin-top: 47px;
                    width: 716px;
                    height: 400px;
                    border-radius: 5px;
                }
            }
            .center-right{
                width: 44%;
                height: 100%;
                // background: rgb(0, 119, 255);
                position: relative;
                .right-bg{
                    position: absolute;
                    top: -30px;
                    right: -78px;
                    width: 280px;
                    height: 326px;
                    z-index: -1;
                }
                .right-content{
                    position: relative;
                    z-index: 0;
                    width: 100%;
                    height: 589px;
                    background:#fff url('../assets/images/logo/chartBg.png') no-repeat;
                    background-size: 340px 230px;
                    // background-attachment:fixed;
                    background-position: 150px 280px;
                    box-shadow: 0px 10px 40px rgba(0, 13, 55, 0.08);
                    border-radius: 4px;
                    top: 39px;
                    padding: 70px 59px;
                    box-sizing: border-box;
                    p{
                        font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
                        font-size: 16px;
                        line-height: 36px;
                        letter-spacing: 2px;
                        color: #111A37;
                    }
                }
                .center-btn{
                    cursor: pointer;
                    color: #fff;
                    width: 160px;
                    height: 60px;
                    background: #0037CC;
                    opacity: 1;
                    border-radius: 44px;
                    text-align: center;
                    line-height: 60px;
                    margin-top: 60px;
                    font-size: 20px;
                    letter-spacing: 2px;
                }
            }
        }
        .main-right{
            width: calc((100% - 1280px) / 2 );
            position: relative;
            img{
                width: 100%;
                position: absolute;
                bottom: 10px;
            }
        }
    }
    .home-mainb{
        width: 100%;
        display: flex;
        justify-content: center;
        background: url('../assets/images/logo/bg2.png');
        height: 1020px;
        box-sizing: border-box;
        align-items: center;
        background-size: 100% 100%;
        position: relative;
        .main-leftb{
            width: calc((100% - 1280px) / 2 );
            img{
                margin-top: 20px;
                width: 100%;
            }
            position: relative;
            top: -344px;
        }
        .main-rightb{
            width: calc((100% - 1280px) / 2 );
            img{
                width: 100%;
            }
        }
        .main-centerb{
            height: 750px;
            overflow: hidden;
            // background: chartreuse;
            @extend %box-common;
            flex-direction: column;
            .centerb-top{
                display: flex;
                justify-content: space-between;
                // box-sizing: border-box;
                .centerb-left{
                    h3{
                        @extend %h3-common;
                        color: #fff;
                    }
                    h4{
                        @extend %h4-common;
                        color: #fff;
                    }
                    .img-circle{
                        @extend %img-circle-common;
                    }
                }
                .centerb-right{
                    width: 50%;
                    div{
                        margin-top: 30px;
                        width: 100%;
                        height: 120px;
                        border-bottom: 1px solid #2B4079;
                        display: flex;
                        justify-content: space-between;
                    }
                    span{
                        display: inline-block;
                        box-sizing: border-box;
                        height: 120px;
                        font-size: 24px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        line-height: 120px;
                        color: #FFFFFF;
                        letter-spacing: 3px;
                        cursor: pointer;
                        // transition: border-bottom 0.5s ease;
                        &.span-active{
                            border-bottom:4px solid #346BFF;
                        }
                    }
                }
            }
            .centerb-bottom{
                width: 100%;
                height: 544px;
                // background: #0037CC;
                display: flex;
                justify-content: space-between;
                .cb-left{
                    width: 23%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    img{
                        width: 100%;
                        height: 48%;
                        border-radius: 4px;
                    }
                }
                .cb-center{
                    width: 40%;
                    height: 100%;
                    box-sizing: border-box;
                    overflow: hidden;
                    background: rgb(141, 61, 61);
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                }
                .cb-right{
                    width: 32%;
                    height: 100%;
                    background: #FFFFFF;
                    border-radius: 4px;
                    box-sizing: border-box;
                    padding: 50px 0;
                    p,h3,h4,.center-btn{
                        width: 287px;
                        margin: 0 auto;
                        font-family: HarmonyOS Sans SC;
                    }
                    h3{
                        font-size: 60px;
                        font-weight: bold;
                        line-height: 59px;
                        color: #D7DBEA;
                        letter-spacing: 3px;
                        margin-bottom: 20px;
                    }
                    h4{
                        font-size: 24px;
                        font-weight: bold;
                        // line-height: 59px;
                        color: #111A37;
                        letter-spacing: 2px;
                        margin-bottom: 30px;
                    }
                    p{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #111A37;
                        letter-spacing: 2px;
                        font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
                    }
                    .center-btn{
                        button{
                            cursor: pointer;
                            font-size: 20px;
                            font-family: HarmonyOS Sans;
                            font-weight: 400;
                            color: #FFFFFF;
                            width: 160px;
                            height: 60px;
                            background: #0037CC;
                            opacity: 1;
                            border-radius: 44px;
                            border: none;
                            letter-spacing: 2px;
                            line-height: 60px;
                            margin-top: 60px;
                        }
                    }
                }
            }  
        }
    }
    .home-mainc{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 140px 0;
        box-sizing: border-box;
        align-items: center;
        position: relative;
        .main-leftb{
            width: calc((100% - 1280px) / 2 );
            img{
                margin-top: -5px;
                width: 100%;
            }
            position: relative;
            top: -192px;
        }
        .main-rightb{
            width: calc((100% - 1280px) / 2 );
            img{
                width: 100%;
            }
            position: relative;
            top: 170px;
        }
        .main-centerb{
            // overflow: hidden;
            // background: chartreuse;
            @extend %box-common;
            flex-direction: column;
            .centerb-top{
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                .centerb-left{
                    h3{
                        @extend %h3-common;
                    }
                    h4{
                        @extend %h4-common;
                    }
                    .img-circle{
                        @extend %img-circle-common;
                    }
                }
                .centerb-right{
                    width: 68%;
                    // background: #725353;
                    p{
                        margin-top:3px;
                        padding-left: 25px;
                        font-size: 18px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        line-height: 38px;
                        color: #A9B3CC;
                        letter-spacing: 1px;
                        position: relative;
                        word-wrap:break-word; white-space: nowrap; 
                        overflow: hidden;
                        &::before{
                            content: '';
                            position:absolute;
                            left: 0;
                            top: 8px;
                            width: 0;
                            height: 36px;
                            border: 1px solid #A9B3CC;
                        }
                    }
                }
            }
            .centerb-bottom{
                width: 100%;
                position: relative;
                ul.center-ula{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    width:100%;
                    position: relative;
                    z-index: 0;
                    // background: #a5e056;
                    li{
                        width: 234px;
                        height: 262px;
                        list-style: none;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        background: #FFFFFF;
                        border: 2px solid #FFFFFF;
                        box-shadow: 0px 10px 40px rgba(0, 34, 125, 0.06);
                        opacity: 1;
                        border-radius: 4px;
                        position: relative;
                        top: 0;
                        // transition: all 5s ease;
                        div{
                            width: 100px;
                            height: 100px;
                            background: #00227D;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 100px;
                            img{
                                width: 52px;
                                height: 52px;
                            }
                        }
                        span{
                            font-size: 24px;
                            font-family: HarmonyOS Sans SC;
                            font-weight: bold;
                            line-height: 40px;
                            color: #111A37;
                            letter-spacing: 3px;
                            margin-top: 25px;
                        }
                        &:hover{
                            top: -20px;
                            transition: all 1s ease;
                            cursor: pointer;
                            span{
                                color: #00227D;
                            }
                        }
                    }
                }
                .cirle-img{
                    width: 203px;
                    height: 203px;
                    position: absolute;
                    top: -40px;
                    right: -56px;
                    z-index: -1;
                }
            }
        }
    }
    .home-maind{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 140px;
        background: url('../assets/images/logo/灰bj@2x.png');
        margin-bottom: 0;
        padding: 140px 0;
        background-size: 100% 100%;
        align-items: center;
        .main-leftb{
            width: calc((100% - 1280px) / 2 );
            img{
                margin-top: -65px;
                width: 100%;
            }
            position: relative;
            top: -272px;
        }
        .main-rightb{
            width: calc((100% - 1280px) / 2 );
            img{
                width: 100%;
            }
            position: relative;
            top: 255px;
        }
        .main-centerb{
            // height: 600px;
            @extend %box-common;
            flex-direction: column;
            position: relative;
            // .img-circleb{
            //     width: 326px;
            //     height: 319px;
            //     position: absolute;
            //     right: -100px;
            //     top: 100px;
            // }
            .centerb-top{
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                .centerb-left{
                    h3{
                        @extend %h3-common;
                    }
                    h4{
                        @extend %h4-common;
                    }
                    .img-circle{
                        @extend %img-circle-common;
                    }
                }
            }
            .centerb-bottom{
                margin-top: 48px;
                position: relative;
                .cirle-imgb{
                    width: 326px;
                    height: 326px;
                    position: absolute;
                    top: -102px;
                    right: -132px;
                }
            }
        }
    }
    .home-maine{
        width: 100%;
        display: flex;
        justify-content: center;
        .imgs-box{
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            img{
                width: 290px;
                height: 290px;
                border-radius: 4px;
            }
        }
        .cirle-img-e{
            position: absolute;
            width: 326px;
            height: 326px;
            top: -210px;
            right: -130px;
        }
        .main-leftb{
            img{
                margin-top: -40px;
            }
        }
        .main-rightb{
            top: 200px;
        }
        .centerb-bottom{
            margin-top: 50px;
        }
    }
}
// @keyframes mymove {
//   from {width: 13px;}
//   to {width: 50px;}
// }
</style>
