import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{
      name:'首页'
    },
    component: function () {
      return import('../views/Home.vue')
    }
  },
  {
    path: '/about',
    name: 'About',
    meta:{
      name:'企业介绍'
    },
    component: function () {
      return import('../views/About.vue')
    }
  },
  {
    path: '/serviceProducts',
    name: 'serviceProducts',
    meta:{
      name:'业务及产品'
    },
    component: function () {
      return import('../views/serviceProducts.vue')
    }
  },
  {
    path: '/solution',
    name: 'solution',
    meta:{
      name:'解决方案'
    },
    component: function () {
      return import('../views/solution.vue')
    }
  },
  {
    path: '/mediaCenter',
    name: 'mediaCenter',
    meta:{
      name:'媒体中心'
    },
    component: () => import('../views/mediaCenter.vue'),
    children: [
      {
        path: 'mediaCenterDetail',
        name: 'detail',
        meta:{
          name:'酒星动态'
        },
        component: () => import('../views/mediaCenterDetail.vue'),
      }
    ]
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    meta:{
      name:'联系我们'
    },
    component: function () {
      return import('../views/contactUs.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from,savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
