<template>
    <div class="footer">
            <div class="footer-top">
                <div class="top-main">
                    <div class="jx">
                        <div class="">
                            <div class="jx_logo">
                                <img
                                    src="../../assets/images/logo/rightlogo.png"
                                    alt=""
                                    style="width:216px;height:62px"
                                />
                            </div>
                            <!-- <div class="line"></div>
                            <p>
                                白酒产业链综合服务商
                            </p> -->
                        </div>
                        <ul class="aboutUs">
                            <li @click="toRoute('/about')">关于我们</li>
                            <li class="line">|</li>
                            <li @click="toRoute('/serviceProducts')">产品业务</li>
                            <li class="line">|</li>
                            <li @click="toRoute('/solution')">解决方案</li>
                            <li @click="toRoute('/mediaCenter')">媒体中心</li>
                            <li class="line">|</li>
                            <li @click="toRoute('/contactUs')">联系我们</li>
                        </ul>
                    </div>
                    <div class="ewm">
                        <div class="ewmBox">
                            <img
                                src="@/assets/images/logo/b3Code.png"
                                style="width:140px;height:140px"
                                alt=""
                            />
                            <div class="download">扫描二维码</div>
                            <div class="download">下载好酒集app</div>
                        </div>
                        <div class="ewmBox">
                            <img
                                src="@/assets/images/logo/酒星科技.jpg"
                                style="width:140px;height:140px"
                                alt=""
                            />
                            <div class="download">扫描二维码</div>
                            <div class="download">关注酒星研酒所</div>
                        </div>
                        <div class="ewmBox">
                            <img
                                src="@/assets/images/logo/酒星研酒所.jpg"
                                style="width:140px;height:140px"
                                alt=""
                            />
                            <div class="download">扫描二维码</div>
                            <div class="download">关注微信订阅号</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="bottom-main">
                    <div style="text-align:left;">
                        Copyright © 1998 - 2021 Jiuxing. All Rights Reserved.
                        四川酒星网络科技有限公司 版权所有
                    </div>
                    <div style="text-align:right;">渝公网安备 11030102010689号</div>    
                </div>
            </div>
    </div>
</template>

<script>
export default {
    name: 'footerBox',
    data() {
        return {}
    },
    methods: {
        toRoute(val) {
            this.$router.push({path: val})
        }
    }
}
</script>

<style lang="scss" scoped>
.footer{
    width: 100%;
    background: #09236B;
    .footer-top{
        height: 478px;
        background: url('../../assets/images/logo/footBg.png');
        background-size: cover;
    }
    .footer-top, .footer-bottom{
        width: 100%;
        .top-main, .bottom-main{
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
    .bottom-top{
        width: 100%;
    }
    .jx {
        .line {
            width: 216px;
            height: 1px;
            background: #ffffff;
            opacity: 1;
        }
        p {
            color: #fff;
            letter-spacing: 8px;
        }
        .aboutUs {
            display: flex;
            margin: 0;
            padding: 0;
            width: 320px;
            flex-wrap: wrap;
            margin-top: 41px;
            li {
                cursor: pointer;
                list-style: none;
                font-size: 16px;
                width: 68px;
                height: 21px;
                color: #b8c0cc;
                margin-top: 20px;
                letter-spacing: 1px;
            }
            .line {
                height: 16px;
                width: 0px;
                margin: 0px 16px;
                margin-top: 20px;
            }
        }
    }
    .ewm {
        width: 500px;
        display: flex;
        justify-content: space-between;
        .ewmBox {
            img{
                width: 140px;
                height: 140px;
                margin-bottom: 16px;
            }
            .download {
                font-size: 16px;
                font-family: HarmonyOS Sans SC;
                font-weight: 400;
                color: #b8c0cc;
                text-align: center;
                opacity: 1;
                letter-spacing: 1px;
            }
        }
    }
    .footer-bottom {
        height: 80px;
        display: flex;
        justify-content: space-between;
        div {
            line-height: 80px;
            font-size: 14px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            letter-spacing: 1px;
            color: #ffffff;
            opacity: 0.5;
        }
    }
</style>
