<template>
    <div id="app">
        <Nav></Nav>
        <main>
            <router-view />
        </main>
        <footerBox></footerBox>
            <a-back-top>
                <div class="ant-back-top-inner">
                    <img src="./assets/images/logo/滑向顶部@2x.png" alt="">
                    <!-- <a-icon type="up" /> -->
                </div>
            </a-back-top>
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import footerBox from '@/components/footer/footer.vue'

export default {
    name: 'Home',
    components: {
        Nav,
        footerBox
    }
}
</script>

<style lang="scss">
body {
    margin: 0;
    padding: 0;
}
#app {
    padding: 0;
    margin: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 1200px;
    width: 100%;
    height: 100%;
}
main{
    width: 100%;
}
#app .ant-back-top {
  bottom: 100px;
}
#app .ant-back-top-inner {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50px;
//   background-color: #00227D;
  text-align: center;
  img{
    height: 60px;
    width: 60px;
  }
}
</style>
